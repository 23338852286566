import React, {useEffect, useRef} from "react";
import {Avatar, Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import {Mic, Send} from "@mui/icons-material";
import {useState} from "react";
import useRecorder from "../../hooks/useRecorder";
import {MainLayout} from "../../layouts/main/MainLayout";
import {grey} from "@mui/material/colors";
import {useParams} from "react-router-dom";
import axios from "../../axios/axios";
import {toast} from "react-toastify";
import useWebSocket from "../../hooks/useWebSocket";
import {AudioPlayer} from "./AudioPlayer";
import {DebugMessage} from "./DebugMessage";

export const ChatRecorder = React.memo(({asriId}) => {
  const [session, setSession] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const {audioMessage, isApproved, isPaused, messageCounter} = useRecorder({sendMessage, session});
  const {id} = useParams();
  const [updatedValue, state] = useWebSocket(`/topic/session/`, id);
  const bottomRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (id) {
      axios.get(`/api/message/session/${id}`)
        .then(response => {
          setMessages(response.data);
          const lastMessage = response.data[response?.data.length - 1];
          if (lastMessage && lastMessage.buttons) {
            setButtons(lastMessage?.text?.split(";"));
          }
        })
        .catch(error => {
          console.log(error);
        })
      axios.get(`/api/session/${id}`)
        .then(response => {
          setSession(response?.data);
        })
        .catch((error) => console.log(error?.message))
      setMessage("");
    } else {
      setMessages([]);
      setButtons([]);
    }
  }, [id])

  // useEffect(() => {
  //   if (session && !session?.documentId && asriId) {
  //     const createAsriDocument = async () => {
  //       try {
  //         const response = await AsriDocumentRepository.createConversation({name: session?.name, session: asriId});
  //         const doc = response.data;
  //         const sessionResponse = await axios.put(`/api/session`, {id: session?.id, documentId: doc?.id});
  //         setSession(sessionResponse?.data);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     }
  //
  //     createAsriDocument();
  //   }
  // }, [session, asriId])

  useEffect(() => {
    if (messages.length && bottomRef.current) {
      bottomRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [messages, bottomRef.current])

  useEffect(() => {
    if (updatedValue && +id === +updatedValue?.session) {
      const newMessage = updatedValue.message;
      setMessages(prevState => {
        const items = [...prevState];
        const index = items.findIndex(i => i.id === newMessage.id);
        if (isFinite(index) && index >= 0) {
          items[index] = newMessage;
        } else {
          items.push(newMessage);
        }
        return items.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0)
      });

      // if (newMessage?.audio && session?.documentId) {
      //   AsriDocumentRepository.addMessage(session?.documentId, {
      //     text: newMessage?.text, textItn: newMessage?.text,
      //     timeStart: 0, timeEnd: 0,
      //     category: "",
      //     coreId: "", audiopath: newMessage?.audio, position: "L"
      //   })
      //     .then(response => {console.log("Done")})
      //     .catch(err => console.log(err));
      // }
    }
  }, [updatedValue])

  useEffect(() => {
    if (!isPaused) {
      setMessage("");
    }
  }, [isPaused])

  async function sendMessage(sendingMessage) {
    if (sendingMessage) {
      try {
        const data = {
          session: id,
          texts: [sendingMessage],
          json: localStorage.getItem("json_url") || null
        }
        const response = await axios.post(`/api/message/multiple`, data)
        // setMessages(prev => ([...prev, sendingMessage]));
        setMessages(prevState => {
          const items = [...prevState];
          for (const item of response.data) {
            const index = items.findIndex(i => i.id === item.id);
            if (isFinite(index) && index >= 0) {
              items[index] = item;
            } else {
              items.push(item);
            }
          }
          return items.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0)
        });
        setMessage("");
      } catch (e) {
        console.log(e);
        toast.error("Failed to send message");
      }
    }
  }

  const onSubmitHandle = (e) => {
    e.preventDefault();
    sendMessage(message)
  }

  return (
    <MainLayout
      // openLink={`/chat/${id}?json_url=${localStorage.getItem("json_url") || ""}`}
      secondary={
        <>
          <AudioPlayer
            selectedMessage={selectedMessage}
            messages={messages}
            isPaused={isPaused}
            session={session}
            setSession={setSession}
          />
        </>
      }
    >
      <Stack direction={"row"} gap={1} sx={{position: 'relative', maxHeight: "calc(100dvh - 60px)", height: "100%"}}>
        <Stack direction={"column"} sx={{
          maxHeight: "calc(100dvh - 60px)", height: "100%",
          // bgcolor: grey[50],
          flex: 1,
        }}>
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              top: "50%", left: "50%",
              transform: "translate(-30%, -100%)",
              backdropFilter: "blur(10px)",
            }}
          >
            <img src={"/logo.svg"} alt={"Logo"} width={"60%"} height={"auto"} style={{filter: "brightness(130%) blur(10px)"}}/>
          </Box>
          <Box sx={{flex: 1, p: 2, overflowY: 'auto', zIndex: 10}}>
            <Stack direction={"column"} maxWidth={"md"} sx={{mx: 'auto'}} gap={1}>
              {
                messages?.map((message, index) => {
                  const prev = messages[index - 1];
                  let isPrev = prev?.sender === message?.sender;
                  const isAgent = message?.sender === "AGENT";
                  const direction = isAgent ? "flex-start" : "flex-end";
                  const isSelected = selectedMessage?.id === message.id;
                  const bgcolor = isAgent ? `#8491F7` : `#EAEAEA`
                  return (
                    message && <Stack key={`${message}-${index}`} direction={"column"} alignItems={direction} gap={1}
                                      maxWidth={"md"}>
                      {!isPrev && <Avatar sx={{background: bgcolor, color: isAgent ? "#FFF" : "dark.main", width: 35, height: 35}}/>}
                      <Box
                        maxWidth={"sm"}
                        onClick={() => setSelectedMessage(prev => (prev?.id === message?.id ? null : message))}
                        sx={{
                          cursor: 'pointer',
                          p: 1.5,
                          borderRadius: 5,
                          borderTopLeftRadius: isAgent ? 5 : "default",
                          borderTopRightRadius: !isAgent ? 5 : "default",
                          boxShadow: isSelected ? 5 : 0,
                          background: bgcolor,
                          color: isAgent ? "#FFF" : "dark.main",
                          fontSize: 12,
                          minWidth: "100px",
                        }}
                      >
                        {message?.text}
                      </Box>
                    </Stack>
                  )
                })
              }
              <div ref={bottomRef}/>
            </Stack>

          </Box>
          <Box sx={{ py: 1}}>
            <Stack
              ref={formRef}
              maxWidth={"md"}
              component={"form"} onSubmit={onSubmitHandle}
              direction={"row"} gap={2} alignItems={"center"}
              sx={{p: 1, pb: 0.5, zIndex: 10, mx: {xs: 1, md: "auto"}, bgcolor: grey[50], borderRadius: 2, border: 1, borderColor: "divider"}}
            >
              <Stack direction={"column"} sx={{flex: 1}}>
                <TextField
                  autoFocus
                  value={message || audioMessage}
                  onChange={e => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey && message) {
                      onSubmitHandle(e);
                    }
                  }}
                  multiline
                  disabled={!isPaused || !!audioMessage}
                  rows={2}
                  sx={{border: 0, "& fieldset": { border: 'none' },}}
                  InputProps={{sx: {fontSize: 12, border: 0, p :1}}}
                  placeholder={"Write your message here..."}
                />
                <Typography fontSize={11} sx={{px: 1}} color={grey[600]}>{messageCounter >= 0 && !!audioMessage && isPaused ? `Sending in ${messageCounter}` : "Hold space to start recording"}</Typography>
              </Stack>
              {
                message ?
                  <Box>
                    <IconButton
                      type={"submit"}
                      disabled={!isPaused || !isApproved}
                      sx={{width: 40, height: 40}}
                      // onClick={() => sendMessage(message)}
                    >
                      <Send/>
                    </IconButton>
                  </Box>
                  :
                  <Box>
                    <IconButton
                      type={"button"}
                      sx={{width: 40, height: 40}}
                      className={`recording-button ${isPaused || !isApproved ? "" : "active"}`}
                    >
                      <Mic/>
                    </IconButton>
                  </Box>
              }

            </Stack>
          </Box>
        </Stack>
        <DebugMessage
          selectedMessage={selectedMessage}
          messages={messages}
        />
      </Stack>
    </MainLayout>
  )
})