import './App.css';
import {CircularProgress, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios, {AUTH_TOKEN} from "./axios/axios";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ChatRecorder} from "./pages/chat-recorder/ChatRecorder";
import {HomePage} from "./pages/home-page/HomePage";
import {NotFound} from "./pages/not-found/NotFound";
import {MainLayout} from "./layouts/main/MainLayout";

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      // default: "#212121"
    },
    light: {
      main: "#FFF"
    },
    primary: {
      main: "rgba(132, 145, 247, 1)"
    },
    dark: {
      main: "#2C2B2A"
    },
    secondary: {
      main: "#D7E8F4"
    }
  },
});

function App() {
  let [searchParams] = useSearchParams();

  const [isAuth, setIsAuth] = useState(null);
  const [asriId, setAsriId] = useState(null);

  useEffect(() => {
    if (searchParams.has("json_url")){
      const json_url = searchParams.get("json_url");
      if (json_url) {
        // Decode the base64-encoded URL
        const decodedUrl = atob(json_url);
        console.log(decodedUrl);

        // Save the decoded URL to localStorage
        localStorage.setItem("json_url", decodedUrl);
      } else {
        console.log("json_url not found in searchParams");
      }
    }
    if (searchParams.has("asri")){
      const asri = searchParams.get("asri");
      console.log("asri:", asri);
      localStorage.setItem("asri", asri);
    } else if (localStorage.getItem("asri")) {
      setAsriId(localStorage.getItem("asri"));
    }
  }, [searchParams])

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.post("/api/user/me");
        const auth = response?.data;
        setIsAuth(auth);
        if (!auth) {
          localStorage.removeItem(AUTH_TOKEN)
        }
        console.log("Checking is auth", auth)
      } catch (e) {
        console.log("Generating token");
        localStorage.removeItem(AUTH_TOKEN)
        const response = await axios.post('/api/user/login', {password: Date.now()})
        if (response?.data?.error) {
          return;
        }
        localStorage.setItem(AUTH_TOKEN, response?.data?.token);
        setIsAuth(true)
      }
    }

    checkAuth();
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        hideProgressBar
        newestOnTop
      />

      {
        isAuth === null ?
          <div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress/></div> :
          <Routes>
            <Route path={"/"} element={<HomePage/>}/>
            <Route path={"/chat/:id"} element={<ChatRecorder asriId={asriId}/>}/>
            <Route path={"/not-found"} element={<MainLayout><NotFound/></MainLayout>}/>
            <Route path={"*"} element={<Navigate to={"/not-found"}/>}/>
          </Routes>
      }

    </ThemeProvider>
  );
}

export default App;
