import {useState, useEffect} from 'react';
import useDebounce from "./useDebounce";
import useSpaceHold from "./useSpaceHold";
import {AsriDocumentRepository} from "../repository/AsriDocumentRepository";

const useRecorder = ({sendMessage, session}) => {
    const [isApproved, setIsApproved] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [websocket, setWebsocket] = useState(null);
    const [processor, setProcessor] = useState(null);
    const [bufferSize] = useState(1024);
    const [message, setMessage] = useState("");
    const [audioMessage] = useDebounce(message, 2000);
    const isSpaceHeld = useSpaceHold();
    const [isSpaceHoldDebounce] = useDebounce(isSpaceHeld, 1000);
    const [sendMessageDebounce, smCounter] = useDebounce(!isSpaceHoldDebounce,  3000);

    // Here we receive message after 5seconds and we need to send to backend
    useEffect(() => {
        if (audioMessage && sendMessageDebounce && smCounter <= 0) {
            setMessage("");
            sendMessage(audioMessage)
        }
    }, [audioMessage, sendMessageDebounce, smCounter])

    // when first time load get user permissions for microphone
    useEffect(() => {
        if (mediaStream === null) {
            getMicPermissions();
        }
    }, [])

    // After user approve stream than create websocket connection
    useEffect(() => {
        if (mediaStream && isApproved && !websocket && websocket?.readyState !== 1) {
            startWebsocket()
        }
        return () => {
            if (websocket) {
                websocket.close();
            }
        };
    }, [mediaStream, isApproved, session]);

    // after we create websocket than recreate onmessage because isPaused
    useEffect(() => {
        if (processor && websocket) {
            processor.onaudioprocess = function (e) {
                // console.log("trying to send stream");
                if (websocket && websocket?.readyState === 1 && (isSpaceHeld === true || isSpaceHoldDebounce)) {
                    var left = e.inputBuffer.getChannelData(0);
                    var left16 = downsampleBuffer(left, 48000, 16000);
                    websocket.send(left16);
                    console.log("stream sent");
                }
            };
        }
    }, [processor, websocket, isSpaceHeld, isSpaceHoldDebounce])

    useEffect(() => {
        const startRecording = async () => {
            if (isSpaceHeld) {
                await checkConnection();
            }
        }
        startRecording();
    }, [isSpaceHeld])

    const startWebsocket = () => {
        if (websocket && websocket?.readyState === 1) {
            return;
        }
        const sendAudioStream = (stream) => {
            try {
                AudioContext = window.AudioContext || window.webkitAudioContext;
                const context = new AudioContext({
                    latencyHint: 'interactive',
                });

                const processor = context.createScriptProcessor(bufferSize, 1, 1);
                processor.connect(context.destination);
                context.resume();
                let input = context.createMediaStreamSource(stream);
                input.connect(processor);

                setProcessor(processor);
            } catch (error) {
                console.error("Error initializing MediaRecorder:", error?.message);
            }
        };

        const ws = new WebSocket(process.env.REACT_APP_VAD_WS);

        ws.onopen = function () {
            console.log("WebSocket connection opened");
            sendAudioStream(mediaStream)
        };

        ws.onmessage = function (e) {
            try {
                console.log("ws: ", e.data)
                let msg = "";
                try {
                    const data = JSON.parse(e.data);
                    msg = data.t;
                } catch (err) {
                    msg = e.data;
                }
                setMessage(prev => `${prev} ${msg}`?.replace(/\s+/g, ' ') || "");
            } catch (error) {
                console.error("Error:", error);
            }
        };

        ws.onclose = function (e) {
            console.log("connection closed (" + e.code + ")");
        };
        ws.onerror = function (event) {
            console.error("WebSocket error:", event);
        };
        setWebsocket(ws);
    }

    function downsampleBuffer(buffer, sampleRate, outSampleRate) {
        if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw 'downsampling rate show be smaller than original sample rate';
        }
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
            var accum = 0,
                count = 0;
            for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    }

    const getMicPermissions = async () => {
        try {
            const response = await navigator.mediaDevices.getUserMedia({audio: true});
            console.log("Microphone permissions granted", response);
            setIsApproved(true);
            setMediaStream(response);
        } catch (error) {
            console.log("Microphone permissions denied");
            setIsApproved(false);
            setMediaStream(null);
        }
    };

    const checkConnection = async () => {
        if (!mediaStream) {
            await getMicPermissions();
        }
        if (!websocket || websocket?.readyState !== 1) {
            startWebsocket();
        }
    }

    return {
        isApproved,
        mediaStream,
        isPaused: !isSpaceHeld,
        audioMessage: message,
        messageCounter: sendMessageDebounce ? smCounter : -1
    };
};

export default useRecorder;
