import axios from 'axios';

export const AUTH_TOKEN = 'token';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_ASRI_GATEWAY}`,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

instance.interceptors.request.use(
    config => {
        // const token = localStorage.getItem(AUTH_TOKEN);
        // if (token) config.headers.Authorization = `${token}`;
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

export default instance;
